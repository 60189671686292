import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import Listtable from "../../utils/ListTable";
import ModalScreen from "../Modal";
import SubscriptionCard from "../SubscriptionCard/SubscriptionCard";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useOutletContext } from "react-router-dom";

const tableHeader = ["Specialization", "Number of Doctor’s"];
const tableHeader2 = ["Education", "Number of Doctor’s"];
const keysName = ["name", "docCount"];
const keysName2 = ["name", "docCount"];
const btnStyle = {
  padding: "0.5rem 2rem",
  color: "#194AF5",
  bgcolor: "#E6EBFF",
  boxShadow: "none",
  borderRadius: "8px",
  ":hover": {
    bgcolor: "#E6EBFF",
    color: "#194AF5",
    boxShadow: "none",
  },
};

function Settings() {
  const [activeTab, setActiveTab] = useState("Subscriptions");
  const [specialization, setSpecialization] = useState([]);
  const [education, setEducation] = useState([]);
  console.log("specialization", specialization);
  console.log("education", education);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [updatedsubscriptionPlans, setUpdatedSubscriptionPlans] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [confirmOpenModal, setconfirmOpenModal] = useState(false);
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const { searchText } = useOutletContext();
  const [list, setList] = useState([]);
  useEffect(() => {
    const data = list?.filter((x) =>
      x?.name?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setSpecialization(data);
  }, [searchText, list]);

  const getSpecialization = (e) => {
    const PayLoad = {
      ...(e &&
        e.target.value && {
          text: e.target.value,
        }),
    };
    const url = e
      ? `/specialization/doctor-count?text=${e?.target?.value}`
      : `/specialization/doctor-count`;

    api
      .get(url, PayLoad)
      .then((res) => {
        if (res) {
          setSpecialization(res?.data?.data?.Specialization);
          setList(res?.data?.data?.Specialization);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };

  const getEducation = (e) => {
    const PayLoad = {
      ...(e &&
        e.target.value && {
          text: e.target.value,
        }),
    };
    const url = e
      ? `/education/doctor-count?text=${e?.target?.value}`
      : `/education/doctor-count`;

    api
      .get(url, PayLoad)
      .then((res) => {
        if (res) {
          setEducation(res?.data?.data.educations);
          console.log(res?.data?.data?.educations);
          // setList(res?.data?.data?.Specialization);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };

  const enableBtn = () => {
    setDisabled(false);
  };

  const getSubscriptionPlans = (e) => {
    const PayLoad = {
      ...(e &&
        e.target.value && {
          text: e.target.value,
        }),
    };
    const url = e ? `/subscription?text=${e?.target?.value}` : `/subscription`;

    api
      .get(url, PayLoad)
      .then((res) => {
        if (res) {
          setSubscriptionPlans(res?.data?.data?.SubscriptionPlans);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };

  const UpdateSubscriptionPlan = async (data) => {
    console.log(updatedsubscriptionPlans);
    const url = `/subscription`;

    const updatePromises = Object.keys(updatedsubscriptionPlans).map((key) =>
      api.patch(`${url}/${key}`, updatedsubscriptionPlans[key])
    );

    try {
      const results = await Promise.all(updatePromises);
      console.log("results", results);
      if (results.length) {
        toast.success("Updated Plans Successfully");
      }

      getSubscriptionPlans();
    } catch (err) {
      toast.error("Cannot add a speciality with duplicate name");
      // toast.error("Error: " + err);
    }
  };

  const handleModal = (e) => {
    setData(e);
    setOpenModal(true);
  };

  const handleModalClose = (buttonText = "save") => {
    setData({});
    if (buttonText !== "close") {
      getSpecialization();
      getEducation();
    }
    setOpenModal(false);
  };

  useEffect(() => {
    getSpecialization();
    getSubscriptionPlans();
    getEducation();
  }, []);

  useEffect(() => {
    if (activeTab === "Subscriptions") {
      getSubscriptionPlans();
    }
  }, [activeTab]);

  const handleSave = () => {
    UpdateSubscriptionPlan();
    setDisabled(true);
  };

  return (
    <Box sx={{ margin: "1rem" }}>
      <Paper sx={{ padding: "1rem", boxShadow: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: "auto",
            }}
          >
            <Box
              onClick={() => {
                setActiveTab("Subscriptions");
              }}
              sx={{
                borderBottom:
                  activeTab === "Subscriptions"
                    ? "2px solid #194AF5"
                    : "2px solid transparent",
                color: activeTab === "Subscriptions" ? "#194AF5" : "#7D8DC4",
                width: "max-content",
                padding: "0.5rem 1rem",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Subscriptions
            </Box>
            <Box
              onClick={() => {
                setActiveTab("Specialization");
              }}
              sx={{
                borderBottom:
                  activeTab !== "Subscriptions" && activeTab !== "Education"
                    ? "2px solid #194AF5"
                    : "2px solid transparent",
                color:
                  activeTab !== "Subscriptions" && activeTab !== "Education"
                    ? "#194AF5"
                    : "#7D8DC4",
                width: "max-content",
                padding: "0.5rem 1rem",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Specialization
            </Box>
            <Box
              onClick={() => {
                setActiveTab("Education");
              }}
              sx={{
                borderBottom:
                  activeTab !== "Subscriptions" &&
                  activeTab !== "Specialization"
                    ? "2px solid #194AF5"
                    : "2px solid transparent",
                color:
                  activeTab !== "Subscriptions" &&
                  activeTab !== "Specialization"
                    ? "#194AF5"
                    : "#7D8DC4",
                width: "max-content",
                padding: "0.5rem 1rem",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Education
            </Box>
          </Box>
          <Button
            sx={{
              ...btnStyle,
              bgcolor: "#194AF5",
              color: "white",
              width: "max-content",
              ":hover": {},
              display: activeTab === "Specialization" ? "block" : "none",
            }}
            variant="contained"
            fullWidth
            onClick={() => handleModal({})}
          >
            Add Specialization
          </Button>
          <Button
            sx={{
              ...btnStyle,
              bgcolor: "#194AF5",
              color: "white",
              width: "max-content",
              ":hover": {},
              display: activeTab === "Education" ? "block" : "none",
            }}
            variant="contained"
            fullWidth
            onClick={() => handleModal({})}
          >
            Add Education
          </Button>
        </Box>
        {activeTab === "Specialization" ? (
          <Box
            sx={{
              margin: "1rem 0",
              padding: "1rem",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
              List of Specialization
            </Typography>
            <Listtable
              tableheader={tableHeader}
              tablebody={specialization}
              dataKeyName={keysName}
              editHandle={handleModal}
              tag={activeTab}
            />
            {openModal && (
              <ModalScreen
                open={openModal}
                onClose={handleModalClose}
                data={data}
                type={activeTab}
              />
            )}
          </Box>
        ) : activeTab === "Education" ? (
          <Box
            sx={{
              margin: "1rem 0",
              padding: "1rem",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
              List of Education
            </Typography>
            <Listtable
              tableheader={tableHeader2}
              tablebody={education}
              dataKeyName={keysName2}
              editHandle={handleModal}
              tag={activeTab}
            />
            {openModal && (
              <ModalScreen
                open={openModal}
                onClose={handleModalClose}
                data={data}
                type={activeTab}
              />
            )}
          </Box>
        ) : (
          <Box
            sx={{
              margin: "1rem 0",
              padding: "1rem",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "1rem",
              }}
            >
              {subscriptionPlans &&
                subscriptionPlans.map((item, index) => (
                  <SubscriptionCard
                    key={item._id}
                    data={item}
                    enableBtn={enableBtn}
                    setPlansToUpdate={(id, updatedData) =>
                      setUpdatedSubscriptionPlans((prev) => ({
                        ...prev,
                        [id]: updatedData,
                      }))
                    }
                  />
                ))}
            </Box>
            <Box
              sx={{
                width: "100%",
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                disabled={disabled}
                onClick={() => setconfirmOpenModal(true)}
                sx={{
                  padding: "0.25rem 1.5rem",
                  boxShadow: "none",
                  marginLeft: "auto",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
      <ConfirmModal
        open={confirmOpenModal}
        close={() => setconfirmOpenModal(false)}
        confirm={() => {
          handleSave();
          setconfirmOpenModal(false);
        }}
        type={"subscription"}
      />
    </Box>
  );
}

export default Settings;
