import React, { useState } from "react";

import cookie from "react-cookies";
import "react-toastify/dist/ReactToastify.css";
//
const userStateContext = React.createContext();
const userDispatchContext = React.createContext();

const UserProvider = ({ children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    !!cookie.load("token")
  );

  return (
    <userStateContext.Provider value={{ isUserAuthenticated }}>
      <userDispatchContext.Provider value={{ setIsUserAuthenticated }}>
        {children}
      </userDispatchContext.Provider>
    </userStateContext.Provider>
  );
};

const useUserState = () => {
  const context = React.useContext(userStateContext);
  // console.log("Context", context);

  return context;
};
const useUserDispatch = () => {
  const context = React.useContext(userDispatchContext);
  return context;
};

export { UserProvider, useUserState, useUserDispatch };
