import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { UpdateDoctorStatus } from "../../api/doctor";

function MuiIcon() {
  return (
    <svg
      style={{ marginRight: "10px" }}
      width="30"
      height="30"
      viewBox="0 0 18 10"
      fill="none"
    >
      <path
        d="M16.5 1.5L9 9L1.5 1.5"
        stroke="#626262"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};
const DoctorModal = ({ open, onClose, doctorData, isEditDoctor }) => {
  console.log("doctorData", doctorData);
  const [_id, setID] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorEmail, setDoctorEmail] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorExperience, setDoctorExperience] = useState("");
  const [validity, setValidity] = useState("");
  const [doctorEducation, setDoctorEducation] = useState([]);
  const [specialities, setSpecialities] = useState({});
  const [doctorStatus, setDoctorStatus] = useState("ACTIVE");
  const [plan, setPlan] = useState("Free Trail");
  const [doctorType, setDoctorType] = useState("DOCTOR");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [allEducations, setAllEducations] = useState([]);
  const [allSpecialities, setAllSpecialities] = useState([]);
  const [active, setActive] = useState("active");

  // console.log("doctorEducation", doctorEducation);
  // console.log("specialities", specialities);
  // console.log("allSpecialities", allSpecialities);

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    setActive(selectedStatus);
    handleStatus(selectedStatus);
  };

  const getSpecialization = () => {
    // setIsLoading(true);
    api
      .get(`/specialization`)
      .then((res) => {
        if (res) {
          setAllSpecialities(res?.data?.data?.Specialization);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };
  const getEducation = () => {
    // setIsLoading(true);
    api
      .get(`/education`)
      .then((res) => {
        if (res) {
          console.log("res", res);
          setAllEducations(res?.data?.data?.educations);
        }
      })
      .catch((err) => {
        toast.error("Error " + err);
      });
  };
  useEffect(() => {
    getSpecialization();
    getEducation();

    if (Object.entries(doctorData).length > 0 && isEditDoctor) {
      setDoctorName(doctorData?.name);
      setDoctorEmail(doctorData?.email);
      setDoctorExperience(doctorData?.experience);
      setDoctorEducation(
        doctorData?.education.map((item) => ({
          name: item.name,
          _id: item._id,
        }))
      );
      setDoctorPhone(doctorData?.phone);
      setDoctorType(doctorData?.role);
      // console.log("allEducations", doctorData?.status?.toLowerCase());
      setActive(doctorData?.status?.toLowerCase());
      setSpecialities(
        doctorData?.speciality?.[0]
          ? {
              name: doctorData.speciality[0].name,
              _id: doctorData.speciality[0]._id,
            }
          : null
      );
      setDoctorStatus(doctorData?.doctorStatus);
      setPlan(doctorData?.subscription?.plan?.name);
      setValidity(doctorData?.subscription?.remainDays);
      setID(doctorData?._id);
      setRegistrationNumber(doctorData?.registrationNumber);
    }
  }, [doctorData]);

  const handlePhone = (value) => {
    if (!isNaN(+value)) {
      setDoctorPhone(value);
    }
  };

  const enableBtn = () => {
    setDisabled(false);
  };

  const handleSubmitDoctor = () => {
    try {
      let doctorPayload = {
        name: doctorName,
        experience: doctorExperience,
        education: doctorEducation,
        speciality: [specialities._id],
        status: doctorStatus,
        type: doctorType,
        days: validity,
        doctorId: _id,
        registrationNumber,
      };
      if (!isEditDoctor) {
        doctorPayload = {
          ...doctorPayload,
          registrationNumber,
          email: doctorEmail,
          phone: doctorPhone,
        };
      } else {
        doctorPayload = { ...doctorPayload, _id };
      }
      api
        .post(`/hospital/doctor`, doctorPayload)
        .then((res) => {
          console.log("res", res);
          onClose();
          toast.success(res && res?.data?.message);
        })
        .catch((err) => {
          toast.error("Error " + err);
        });
    } catch (error) {
      toast.error(`Error ${error}`);
    }
  };
  const handleStatus = async (status) => {
    const data = { status: status.toUpperCase() }; // Convert status to uppercase
    const response = await UpdateDoctorStatus(data, _id);
    console.log("status", response);
    if (response?.status === 200) {
      toast.success(response && response?.data?.message);
      console.log(response);
      onClose();
    } else {
      console.log("Update Failed");
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={() => onClose("close")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="space-between"
            alignItems={"flex-end"}
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography
                variant="h5"
                component="h5"
                align="center"
                fontWeight={"600"}
              >
                Edit Doctor
              </Typography>
            </Grid>
            <Box
              sx={{
                width: "130px",
              }}
            >
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={active}
                  onChange={handleChange}
                  IconComponent={MuiIcon}
                >
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Typography
              variant="h6"
              component="h6"
              fontWeight={"600"}
              fontSize={"1rem"}
            >
              Personal Details
            </Typography>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item sm={12} lg={6} md={6}>
              <TextField
                disabled
                fullWidth
                type={"text"}
                value={doctorName}
                label="Name"
                name="name"
                onChange={(e) => {
                  enableBtn();
                  setDoctorName(e.target.value);
                }}
                size="small"
              />
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <TextField
                fullWidth
                disabled
                type={"text"}
                label="Email"
                name="email"
                // disabled={isEditDoctor}
                value={doctorEmail}
                onChange={(e) => {
                  enableBtn();
                  setDoctorEmail(e.target.value);
                }}
                size="small"
              />
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <TextField
                fullWidth
                type={"text"}
                label="Phone"
                name="phone"
                disabled
                onChange={(e) => {
                  enableBtn();
                  handlePhone(e.target.value);
                }}
                value={doctorPhone}
                inputProps={{ maxLength: 10 }}
                size="small"
              />
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <Autocomplete
                disabled
                fullWidth
                disableClearable={true}
                multiple
                size="small"
                value={doctorEducation}
                onChange={(event, newValue) => {
                  enableBtn();
                  setDoctorEducation(newValue);
                }}
                id="tags-standard"
                options={allEducations}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField label="Education" {...params} variant="outlined" />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={1}
              display="flex"
              justifyContent="left"
              sx={{ mt: 2, width: "100%", ml: 2 }}
            >
              <Grid item sx={{ mb: 0 }}>
                <Typography
                  variant="h6"
                  component="h6"
                  fontWeight={"600"}
                  fontSize={"1rem"}
                >
                  Professional details
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <Autocomplete
                disabled
                options={allSpecialities}
                value={specialities}
                onChange={(e, newValue) => {
                  enableBtn();
                  setSpecialities(newValue);
                }}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      ".MuiAutocomplete-tag": {
                        borderRadius: "4px",
                        height: "40px",
                        padding: "0 4px",
                        background: "#E6EBFF",
                      },
                      path: {
                        fill: "black",
                      },
                    }}
                    {...params}
                    name="specialities"
                    label="Specialization"
                    size="small"
                  />
                )}
              />
            </Grid>
            {doctorType !== "RECEPTION" && (
              <>
                <Grid item sm={12} lg={6} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    type={"text"}
                    label="Registration Number"
                    name="registrationNumber"
                    // disabled={isEditDoctor}
                    onChange={(e) => {
                      enableBtn();
                      const value = e.target.value;
                      if (!isNaN(+value)) {
                        setRegistrationNumber(e.target.value);
                      }
                    }}
                    value={registrationNumber}
                    size="small"
                  />
                </Grid>
                <Grid item sm={12} lg={6} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    type={"text"}
                    label="Experience"
                    size="small"
                    name="experience"
                    value={doctorExperience}
                    onChange={(e) => {
                      enableBtn();
                      setDoctorExperience(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  justifyContent="left"
                  sx={{ mt: 2, width: "100%", ml: 2 }}
                >
                  <Grid item sx={{ mb: 0 }}>
                    <Typography
                      variant="h6"
                      component="h6"
                      fontWeight={"600"}
                      fontSize={"1rem"}
                    >
                      Subscription details
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item sm={12} lg={6} md={6}>
              <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                <TextField
                  fullWidth
                  type={"text"}
                  disabled
                  size="small"
                  name="Active Plan"
                  value={plan}
                  // onChange={(e) => setDoctorExperience(e.target.value)}
                />
                {/* <Select
                  size="small"
                  labelId="demo-select-small-label-doctor"
                  id="demo-select-small-deoctor"
                  value={plan}
                  label="Free Trial"
                  disabled={!isEditDoctor}
                  onChange={(e) => setPlan(e.target.value)}
                >
                  <MenuItem value={"FREE"}>Free Trial</MenuItem>
                  <MenuItem value={"STANDARD"}>Standard</MenuItem>
                </Select> */}
              </FormControl>
            </Grid>
            <Grid item sm={12} lg={6} md={6}>
              <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                <TextField
                  fullWidth
                  type={"text"}
                  label="Plan Expired in (Days)"
                  size="small"
                  name="Plan Expired in (Days)"
                  value={validity}
                  onChange={(e) => {
                    enableBtn();
                    setValidity(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              sm={12}
              lg={12}
              md={12}
              display="flex"
              justifyContent="right"
            >
              <Button
                sx={{
                  marginRight: "15px",
                  bgcolor: "white",
                  border: "1px solid #194AF5 ",
                  color: "#194AF5 ",
                  padding: "0.25rem 1.5rem",
                  boxShadow: "none",
                  ":hover": {
                    bgcolor: "white",
                    border: "1px solid #194AF5 ",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={() => onClose("close")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleSubmitDoctor}
                sx={{ padding: "0.25rem 1.5rem", boxShadow: "none" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <ConfirmModal
            open={deleteModal}
            close={() => setDeleteModal(false)}
            type={"doctors"}
            confirm={() => {
              setDeleteModal(false);
              onClose();
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default DoctorModal;
