import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./Login.css";
import { toast } from "react-toastify";
import api from "../../utils/Api";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useUserDispatch } from "../../utils/AdminContext";
import CustomTextField from "../CustomTextField/CustomTextField";
import topBg from "../../assets/images/top_bg.png";
import bottomBg from "../../assets/images/btm_bg.png";

const Login = () => {
  const [screen, setSecreen] = useState("login");
  const [mobileNumber, setMobileNumber] = useState("");
  const { setIsUserAuthenticated } = useUserDispatch();
  const [isMail, setIsMail] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [secs, setSecs] = useState(30);
  const [isRunning, setIsRunning] = useState(false);
  const [otp, setOtp] = useState("");

  const handleOTP = () => {
    if (otp.length < 6) {
      toast.error(`Please Enter a Valid OTP`);
    }
    try {
      const isMobile = !!mobileNumber.includes("@");

      const PayloadOfOtp = {
        ...(isMobile ? { email: mobileNumber } : { phone: mobileNumber }),
        otp: otp,
      };
      api
        .post("/auth/verify-admin", PayloadOfOtp)
        .then(async (response) => {
          cookie.save("token", response.data.token);
          await setIsUserAuthenticated(true);
          navigate("/doctors");
        })
        .catch((err) => {
          toast.error(`Couldn't verify OTP`);
        });
    } catch (error) {
      toast.error(`Error:${error}`);
    }
  };

  const onPressSignIn = () => {
    if (mobileNumber.length < 10) {
      toast.error(`Please Enter a Valid Mobile Number`);
    } else {
      try {
        const isMobile = !!mobileNumber.includes("@");
        // console.log(mobileNumber.includes("@"));
        setIsMail(mobileNumber.includes("@"));

        const payload = {
          ...(isMobile ? { email: mobileNumber } : { phone: mobileNumber }),
        };
        api
          .post("/auth/admin/request", payload)
          .then((response) => {
            setSecreen("otp");
            startTimer();
            setOtp("");
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setOtp("");
          });
      } catch (error) {
        toast.error(error);
        setOtp("");
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMobileNumber(value);
  };
  useEffect(() => {
    let timer;
    if (isRunning && secs > 0) {
      timer = setInterval(() => {
        setSecs((prevSecs) => {
          if (prevSecs <= 1) {
            clearInterval(timer);
            setIsRunning(false);
            return 0;
          }
          return prevSecs - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning, secs]);

  const startTimer = () => {
    setSecs(30);
    setIsRunning(true);
  };
  // useEffect(() => {
  //   if (screen === "otp") startTimer();
  // }, []);
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{ position: "absolute", top: 0, right: 0 }}
        src={topBg}
        width={500}
        height={500}
        alt="logo"
      />
      <img
        style={{ position: "absolute", bottom: 0, left: 0 }}
        src={bottomBg}
        width={500}
        height={500}
        alt="logo"
      />
      <Box
        sx={{
          zIndex: 100,
          width: "50%",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid sx={{ m: 3 }}>
          <img
            alt=""
            src={require("../../assets/images/logo.png")}
            height="110"
            width="110"
          />{" "}
        </Grid>
        <Typography
          sx={{ pb: 1, fontWeight: "500" }}
          color={"black"}
          component="h1"
          variant="h4"
        >
          {t("WELCOME_BACK")}
        </Typography>
        <Typography
          sx={{
            pb: 2,
            textAlign: "center",
            fontWeight: "400",
            fontSize: "20px",
          }}
          color={"#626262"}
        >
          {screen === "login" ? t("SIGNIN_LINE") : t("OTP_LINE")}
        </Typography>
        <Box
          noValidate
          sx={{ mt: 1, minWidth: "40%", width: "100%", textAlign: "center" }}
        >
          {screen === "login" ? (
            <CustomTextField
              id="mobile"
              placeholder={t("MOBILE_NUMBER_EMAIL")}
              name="mobile"
              autoFocus
              onChange={(e) => handleChange(e)}
              value={mobileNumber}
            />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ pb: 1, fontWeight: "500", fontSize: "1rem" }}
                  color={"black"}
                >
                  {!isMail && "+91"} {mobileNumber}
                </Typography>
                <div onClick={() => setSecreen("login")}>
                  <Typography
                    color={"primary"}
                    sx={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {isMail ? "Edit Email" : "Edit Phone Number"}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: ".2rem",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginBottom: "2rem",
                  marginTop: "0",
                }}
              >
                <svg width={25} height={25} viewBox="0 0 32 32" fill="none">
                  <g clipPath="url(#clip0_4184_16343)">
                    <path
                      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                      stroke="#B7B7B7"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 9V16H23"
                      stroke="#B7B7B7"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4184_16343">
                      <rect width={32} height={32} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Typography
                  color={"#B7B7B7"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    minWidth: "35px",
                  }}
                >
                  {secs}s
                </Typography>
                <div onClick={onPressSignIn}>
                  <Typography
                    color={isRunning ? "#b7b7b7" : "primary"}
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Resend OTP
                  </Typography>
                </div>
              </Box>
              <MuiOtpInput
                sx={{
                  // border: "1px solid #F3F3F3",
                  ".MuiOutlinedInput-root fieldset": {
                    borderColor: "#F3F3F3",
                  },
                }}
                value={otp}
                onChange={(value) => setOtp(value)}
                length={6}
              />
            </>
          )}

          {screen === "login" ? (
            <Button
              onClick={onPressSignIn}
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#194AF5",
                height: "56px",
                fontSize: "18px",
              }}
            >
              {t("SIGNIN")}
            </Button>
          ) : (
            <>
              <Button
                onClick={handleOTP}
                fullWidth
                variant="contained"
                sx={{ mt: 3, height: "56px", fontSize: "18px" }}
              >
                {t("VERIFY_OTP")}
              </Button>
              {/* <Button
                onClick={onPressSignIn}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, height: "56px", fontSize: "18px" }}
              >
                {t("RESEND_OTP")}
              </Button> */}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
